import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar'





function BusinessHistory() {
    const [businessHistory, setBusinessHistory] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyCount, setKeycount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState('');
    const [progress, setProgress] = useState(0);
    const [isTrue, setIsTrue] = useState(true);

    useEffect(() => {
        getFundHistory();
    }, [page, limit]);

    const getFundHistory = async () => {

        setIsTrue(true);
        setProgress(30);
        let res = await _fetch(`${api_url}income/businessHistory?limit=${limit}&page=${page}`, "GET", {})

        if (res?.status === "success") {
            // setProgress(50);
            setBusinessHistory(res?.data?.data);
            setTotalRecords(res?.total);
            setProgress(100);
            setIsTrue(false);

        }
        if (page > 1) {
            setKeycount(0);
            var count = page - 1;
            setKeycount(count * limit);
        }
    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setPage(newOffset);
    };

    function searchSubmit() {
        setSearch(searchValue);
    }


    return (
        <>

            <LoadingBar
                color='var(--primary-color)'
                progress={progress}
                containerClassName="LoadingBar_contain"
                className="loaderbar-s"
                onLoaderFinished={() => setProgress(0)}
            />
            <div className={isTrue ? 'loader-bg' : ''}></div>

            <div className="in-table pb-4">
                <div className="top-simple-heading">
                    <h1 className="heading-h1">Business History</h1>
                    {/* <div className="top-search">
                        <form action="">
                            <div className="top-sech">
                                <input
                                    className="input-fields"
                                    type="search"
                                    placeholder="Search Fund History "
                                    onChange={(e: any) => setSearchValue(e.target.value)}
                                    value={searchValue}
                                />
                                <button type="submit" className="search-bs" onClick={searchSubmit}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div> */}
                </div>

                <div className="coustom_container">
                    <div className="table_main table_main_comun">

                        <div className="outer-table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">User ID</th>
                                        <th scope="col">Amount</th>
                                        {/* <th scope="col">Type</th>
                                        <th scope="col">Credit Type</th>
                                        <th scope="col">Remark</th> */}
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessHistory?.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{page > 1 ? index + keyCount + 1 : index + 1}</td>
                                                    <td>{item?.username}</td>
                                                    <td>${item?.package}</td>
                                                    {/* <td> {item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</td>
                                                    <td>{item?.amount > 0 ? "Credit" : "Debit"}</td>
                                                    <td>{item?.remark}</td> */}
                                                    <td>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                                </tr>

                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={totalRecords / 10}
                                previousLabel="<"
                            />}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default BusinessHistory