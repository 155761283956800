import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AttachMoneyRounded, HomeTwoTone, Newspaper } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import toasted from "../config/toast";
import LoadingBar from 'react-top-loading-bar';
import Loader from "../config/Loader";


const CommissionPackge = () => {

  const [allData, setAllData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [btnDisable, setbtnDisable] = useState(false);


  useEffect(() => {
    getPackageData();
  }, []);

  const getPackageData = async () => {

    setIsTrue(true)
    setProgress(10);

    let res = await _fetch(`${api_url}settings/packages`, "GET", {})
    if (res?.status === "success") {

      // setProgress(50);
      setAllData(res?.data);
      setAmount(res?.data[0]?.commission);
      setProgress(100);
      setIsTrue(false);
    }
  }

  const SubmitPackge = async () => {
    setbtnDisable(true)
    let data = {
      "amount": amount,
    }
    let res = await _fetch(`${api_url}settings/setupCommisionPackage`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
      getPackageData();
      setbtnDisable(false)

    }
    else {
      toasted.error(res?.message);
      setbtnDisable(false)

    }
  }

  return (
    <>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="page_info">
        <div className="coustom_container">
          <div className="d-flex align-items-center justify-content-center">

            <h6> <Newspaper /> Commission Package</h6>
            <div className="inner_links_header">


              <a href="#" className="d-flex"> <HomeTwoTone className="me-2" /> Home</a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-details-user">
        <div className="coustom_container">
          <div className="tabl-news-card">
            <div className="row m-0">
              <div className="col-md-4">

                <div className="card first-card">
                  <div className="card-header">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AttachMoneyRounded className="icon payout" />
                    </div>
                    <div className="card-ryt-text">
                      <p>Update Commission </p>
                    </div>
                  </div>

                  <div className="">
                    <form>
                      <div className="form-group mb-3 mt-3">
                        <label htmlFor="newsTItle">Amount</label>
                        <input type="text" className="form-control" id="newsTItle" placeholder="Amount" name="newsTItle" onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                      </div>

                      <div className="">
                        <button type="button" className="btn btn-news" onClick={SubmitPackge} disabled={btnDisable}>

                          {btnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {btnDisable === false && 'Submit '}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="outer-table table-responsive ps-md-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Package Title</th>
                        <th>Package	Range</th>
                        <th>Commission</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr>
                              <td>{item?.title} </td>
                              <td className="date-sec text-nowrap">{item?.package_range ? `$${item?.package_range.replace(",", "-$")}` : "-"}</td>
                              <td className="date-sec text-nowrap"> {item?.commission ? item?.commission + "%" : ''}</td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default CommissionPackge;
