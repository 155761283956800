import React, { useEffect, useState } from "react";
import { AttachMoneyRounded, HomeTwoTone, Newspaper } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import _fetch from "../config/api";
import { StakeContractAddress, api_url, ReStakeContractAbi, ReStakeContractAddress, StakeContractAbi, contractAbi, contractAddress, supportedChainId, titleDescription } from "../config/config";
import toasted from "../config/toast";
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';


const PriceSettings = (props) => {

  const [tokenValue, settokenValue] = useState('0');
  const [apiPrice, setapiPrice] = useState('0');


  const [amount, setAmount] = useState("");
  const [getSopnserWallet, setgetSopnserWallet] = useState('');
  const [gethash, setGethash] = useState('');

  const [approveLoader, setapproveLoader] = useState('0');
  const [changeSection, setchangeSection] = useState(false);
  const [activeLogin, setactiveLogin] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);

  useEffect(() => {
    getData();
  }, []);


  const getData = async () => {

    // setIsTrue(true)
    // setProgress(10);

    let res = await _fetch(`${api_url}settings/settings`, "GET", {})

    if (res?.status === "success") {
      settokenValue(res?.data?.tokenValue);
      setapiPrice(res?.data?.tokenValue);

      // setProgress(100);
      // setIsTrue(false);
    }
  }
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [StakeContract, setStakeContract] = useState(null);
  const [ReStakeContract, setReStakeContract] = useState(null);

  const [web3Useff, setWeb3UseEff] = useState(0);


  const connectWallet = async () => {
    // console.log('asdasd', web3);
    setdisconnectStataus(false)

    // setWeb3UseEff(web3Useff ==0 ? 1:0);
    // setdisconnectStataus(false)
    if (web3) {
      try {
        web3.eth.getChainId().then(async chainIdd => {
          // console.log(chainIdd)
          const chainId = chainIdd.toString().slice(0, -1);
          const hexChainId = await web3.utils.toHex(chainId);
          const checkChain = await supportedChainId.includes(hexChainId);
          // console.log(checkChain, hexChainId);
          // const newChain = chainId.toString().slice(0,-1);
          if (checkChain === false) {
            // console.log("test");
            switchNetwork(supportedChainId[0])
          }
        }

        );

        // console.log('chainId',chainId);
        await web3.currentProvider.enable(); // Prompt user to connect
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          console.log('accounts[0]', accounts[0]);
          getCoinBalances(web3, accounts);
          getTokenBalances(web3, accounts);
          // console.log(formattedCoinBalance);
        }
        // setAccount(accounts[0]);
      } catch (error) {
        // console.error('Error connecting wallet:', error);
      }
    }
  };

  const switchNetwork = async (receiveChainId) => {
    if (web3) {
      try {
        // console.log(receiveChainId);
        await web3.currentProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: receiveChainId.toString(), // BSC Testnet chain ID
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'], // Testnet RPC URL
              blockExplorerUrls: ['https://bscscan.com'], // Testnet explorer URL
            },
          ],
        });

        // The user might need to reconnect their wallet after switching networks
      } catch (error) {
        // console.error('Error switching network:', error);
      }
    }
  };



  const getCoinBalances = async (web3Instance, accounts) => {
    const getCoinBalance = await web3Instance.eth.getBalance(accounts[0]);
    const formattedCoinBalance = web3Instance.utils.fromWei(getCoinBalance, 'ether');
    if (formattedCoinBalance > 0) {
      setCoinBalance(formattedCoinBalance);
    }
  }

  const getTokenBalances = async (web3Instance, accounts) => {
    const contract = new web3Instance.eth.Contract(contractAbi, contractAddress);
    setTokenContract(contract);

    // const currency = await contract.methods.symbol().call();
    const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    const tokenDecimals = await contract.methods.decimals().call();
    const tokenDecimalsMain = await parseInt(tokenDecimals.toString().replace("n", ""));
    const formattedBalance = getTokenBalance.toString().replace("n", "") / (10 ** tokenDecimalsMain);

    if (formattedBalance > 0) {
      setTokenBalance(formattedBalance);
    }



    const tokenContractInstance = new web3Instance.eth.Contract(StakeContractAbi, StakeContractAddress);
    // console.log(tokenContractInstance);
    setStakeContract(tokenContractInstance);

    const RestaketokenContractInstance = new web3Instance.eth.Contract(ReStakeContractAbi, ReStakeContractAddress);
    // console.log(tokenContractInstance);
    setReStakeContract(RestaketokenContractInstance);
  }




  const passprops = {
    coinBalance: parseFloat(coinBalance).toFixed(3),
    tokenBalance: parseFloat(tokenBalance).toFixed(3),
    address: account,
    // approveLoader: approveLoader,
    // changeSection: changeSection,
    // activeLogin: activeLogin,
    // setgetSopnser: setgetSopnser,
    // getSopnser: getSopnser,
    web3: web3,
    tokenContract: tokenContract,
    StakeContract: StakeContract,
    ReStakeContract: ReStakeContract,



    // setgetSopnserWallet: setgetSopnserWallet,
  }

  useEffect(() => {
    // registerUser()
    connectWallet();
    if (disconnectStataus !== true) {
      const loadWeb3 = async () => {
        const provider = await detectEthereumProvider() || new InjectedConnector({ supportedChainIds: supportedChainId });

        if (provider) {
          if (provider.isMetaMask) {
            provider.autoRefreshOnNetworkChange = false;
          }
          // console.log('set web 3');
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);

          // 

          provider.on('accountsChanged', (newAccounts) => {
            if (newAccounts.length === 0) {
              // User disconnected wallet
              // setWeb3(null);
              setAccount(null);
            } else {
              setAccount(newAccounts[0]);
              // console.log('newAccounts[0]',newAccounts[0]);
              // getCoinBalances(web3Instance, newAccounts);
              // getTokenBalances(web3Instance, accounts);


            }
          });

          // 

          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            // console.log('accounts[0]',accounts[0]);
            // getCoinBalances(web3Instance, accounts);
            // getTokenBalances(web3Instance, accounts);
            // console.log(formattedCoinBalance);
          }


        }
      };

      loadWeb3();
    }
  }, [account, disconnectStataus]);

  const disconnectWallet = async () => {
    // console.log('dis', web3);
    localStorage.clear();

    // if (web3) {
    //   web3.providers.HttpProvider.prototype.disconnect();
    setWeb3(null);
    setAccount(null);
    // }
    setdisconnectStataus(true)

    //     const ethereum = window.ethereum
    //       if (ethereum) {
    //       // Listening to Event
    //       ethereum.on('disconnect', () => {
    // console.log("MetaMask discconnected")
    //         // this.setState({ metamaskHasDisonnected: true })
    //   })
    // }
  };

  const approveAndSendTokens = async () => {

    // const finalPrice = (tokenValue)?.toString().replace(".", '');
    const finalPrice = web3.utils.toWei(`${tokenValue}`, 'ether'); // Replace with the desired approval amount

    console.log('finalPrice', finalPrice);
    console.log('web3', web3);
    console.log('account', account);
    console.log('tokenContract', tokenContract);
    console.log('StakeContract', StakeContract);

    if (web3 && account && tokenContract) {
      setapproveLoader('1')

      try {


        const getEstimatedGas = await StakeContract.methods.updatePrice(finalPrice).estimateGas({ from: account });
        const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
        const gasPrice = await web3.eth.getGasPrice();
        // console.log('gasPrice', gasPrice, estimatedGas, approvalAmount);
        await StakeContract.methods.updatePrice(finalPrice.toString()).send({
          from: account,
          gasPrice: 3000000000 * 1.3,
          gasLimit: estimatedGas
        }).once('transactionHash', function (hash) {
          // console.log('transactionHash hash: ', hash);
          setbtnDisable(true)

          // console.log(hash);
          // setGethash(hash)
          setchangeSection(true)
        }).on('error', function (error) {
          // console.log('\n[ERROR]\n\n' + error
          //   .message);
          let msg = error.message.split(":");
          // toastr.error("Error " + error.code +
          //     " : " + msg[1]);
        }).then(async function (receipt) {
          // call node apiLOG
          // console.log('receipt', receipt);
          submitData(receipt.transactionHash)
          setactiveLogin(true)
          // setGethash(receipt.transactionHash)


        })

        // console.log('Tokens approved and sent successfully');
      } catch (error) {
        console.error('Error approving and sending tokens:', error);
        toasted.error(error?.data?.message);

      }
    }
  };



  const submitData = async (hash) => {
    let data = {
      "hash": hash,
      "tokenValue": tokenValue,
    }
    let res = await _fetch(`${api_url}settings/tokenPriceAccess`, "POST", data)

    if (res?.status === "success") {
      toasted.success(res?.message);
      getData();
    }

    else {
      toasted.error(res?.message);
    }
  }


  return (
    <>

      {/* <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      /> */}

      {/* <div className={isTrue ? 'loader-bg' : ''}></div> */}

      <div className="page_info">
        <div className="coustom_container">
          <div className="d-flex align-items-center justify-content-center">

            <h6> <Newspaper /> Price Settings</h6>
            <div className="inner_links_header">


              <a href="#" className="d-flex"> <HomeTwoTone className="me-2" /> Home</a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-details-user">
        <div className="coustom_container">
          <div className="tabl-news-card">
            <div className="row m-0">
              <div className="col-md-4">

                <div className="card first-card">
                  <div className="card-header">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AttachMoneyRounded className="icon payout" />
                    </div>
                    <div className="card-ryt-text">
                      <p>Price Settings</p>
                      <button onClick={connectWallet} className="btn btn-success btn-sm">Connect Wallet</button>
                    </div>
                  </div>

                  <div className="">
                    <form>
                      <p>{account}</p>


                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="token_price"> Token Price</label>
                        <input type="text" className="form-control" id="token_price" placeholder="Token Price" name="token_price" value={tokenValue} onChange={(e) => settokenValue(e.target.value)} />
                      </div>

                      <div className="">
                        <button type="button" className="btn btn-news" onClick={approveAndSendTokens} /* disabled={btnDisable}  */>
                        Submit
                          {/* {btnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {btnDisable === false && 'Submit '} */}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="outer-table table-responsive">
                  <div className="t-head">
                    <div className="t-head-left">
                      <h5>Price Settings</h5>

                    </div>


                  </div>

                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td className="date-sec text-nowrap"> ${apiPrice}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceSettings;
