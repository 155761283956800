import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';

const Income = (props: any) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalAmount: "", todayAmount: "", hours24Amount: "", month1Amount: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }

    const Data = await _fetch(`${api_url}income/incomeHistory?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
    if (Data?.status === "success") {
      
      // setProgress(50);
      settableRecords(Data?.data?.data);
      setAllDetail(Data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">
          <div className="page_info">
            <div className="coustom_container">
              <div className="d-flex align-items-center justify-content-center">

                <h6> <PersonRounded /> User Details </h6>
                <div className="inner_links_header">

                  <FiberManualRecord className="dot" />
                  <a href="#">Home</a>
                </div>
              </div>
            </div>
          </div>

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Income
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> ${Number(allDetail?.totalAmount)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                       Today Income
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-success"> <span className="de-sc"></span> ${Number(allDetail?.todayAmount)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                      24H Income
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-warning"> <span className="de-sc"></span> ${Number(allDetail?.hours24Amount)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                      Monthly Income
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-danger"> <span className="de-sc"></span> ${Number(allDetail?.month1Amount)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>




        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>All Incomes</h1>
              <form action="">
                <div className="form-group">
                  <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                    <option value={'username'}>Username</option>

                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="search"
                    placeholder="Search"
                    onChange={(e: any) => setSearchedValue(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Level</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val: any, key: any) => {
                        return (
                          <tr>
                            <td>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>{val?.username}</td>
                            <td>${val?.amount?.toFixed(8)}</td>
                            <td>{val?.type === 'direct_income' ? "Direct Profit" :val?.type === 'level_income' ? "Layered Investment Returns" : val?.type === 'roi_income' ? "Profit on Investment" : val?.type === 'royalty_income' ? "Royalty Profit" :  val?.type === 'global_lifetime_pool_income' ? "Eternal Global Pool Profit" : val?.type}</td>
                            <td>{val?.description}</td>
                            <td>{val?.level}</td>
                            <td>{dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Income;
