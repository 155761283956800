import { AttachMoneyRounded, MoreVertRounded, MenuRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import avtar from '../Assets/images/user.png';
import no_data from '../Assets/images/no-data.png';
import { Dropdown } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import _fetch from '../config/api';
import { api_url } from '../config/config';
import { CountryDropdown } from 'react-country-region-selector';
import LoadingBar from 'react-top-loading-bar'
import ReactPaginate from 'react-paginate';
import dateformat from "dateformat";
import toasted from '../config/toast';

const ViewUser = () => {

    const { username } = useParams();

    // input readonly on off user info and wallet 
    const [readOnly, setReadOnly] = useState(true);
    const [inputfocus, setInputfocus] = useState(false);
    const [readOnlywallet, setReadOnlywallet] = useState(true);
    const [inputfocuswallet, setinputfocuswallet] = useState(false);
    const [readOnlypassword, setReadOnlypassword] = useState(true);
    const [inputfocuspassword, setinputfocuspassword] = useState(false);
    const [allData, setAllData] = useState({ TotalDeposit: "", AvaliableDeposit: "", totalIncome: "", avaliableIncome: "", directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", totalRebuyWallet: "", royaltyIncome: "", totalTeam: "", totalActiveTeam: "", totalInactiveTeam: "", stakingIncentive: "", directBusinessIncentive: "", levelBusinessIncentive: "", royalityIncentive: "", permanentReward: "", differenceBusinessIncentive: "", sponserInfo: { user_info: { name: "", email: "", package: { current_package: "" } } }, username: "", userInfo: { user_info: { name: "", firstName: "", lastName: "", bio: "", wallet_addresses: { BEP20: "", TRC20: "" } } } });
    const [userData, setUserData] = useState({ email: "", phone: "", sponsor_id: "", username: "", directs: { total: "" } });
    const [sponsorData, setSponsorData] = useState({ username: "", created_at: "" });
    const [incomeData, setIncomeData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bio, setBio] = useState('');
    const [BEP20, setBEP20] = useState('');
    const [TRC20, setTRC20] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [country, setCountry] = useState('');
    const [progress, setProgress] = useState(0);
    const [isTrue, setIsTrue] = useState(true);
    const [selectedType, setSelectedType] = useState('staking_incentive');
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState('-created_at');
    const [limit, setLimit] = useState(10);
    const [keyCount, setKeycount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const [isCheckBlockStatus, setIsCheckBlockStatus] = useState(false);
    const [isCheckWithdrawStatus, setIsCheckWithdrawStatus] = useState(false);
    const [legBusiness, setLegBusiness] = useState({ firstleg: 0, secondleg: 0, thirdleg: 0 });
    const [userRank, setUserRank] = useState({ rank: 0, rank_name: "" });


    const [rankArray, setRankArray] = useState([

        { "business": 2500, "total_business": 2500, "rank_name": "Beginner", "rank_level": 1, "amount": 10 },
        { "business": 5000, "total_business": 7500, "rank_name": "Apprentice", "rank_level": 2, "amount": 25 },
        { "business": 15000, "total_business": 22500, "rank_name": "Journeyman", "rank_level": 3, "amount": 60 },
        { "business": 50000, "total_business": 72500, "rank_name": "Expert", "rank_level": 4, "amount": 150 },
        { "business": 100000, "total_business": 172500, "rank_name": "Master", "rank_level": 5, "amount": 350 },
        { "business": 300000, "total_business": 472500, "rank_name": "Elite", "rank_level": 6, "amount": 1000 },
        { "business": 1500000, "total_business": 1972500, "rank_name": "Champion", "rank_level": 7, "amount": 5200. },
        { "business": 5000000, "total_business": 6972500, "rank_name": "Grandmaster", "rank_level": 8, "amount": 17000 },
        { "business": 7500000, "total_business": 14472500, "rank_name": "Legend", "rank_level": 9, "amount": 30000 },
        { "business": 10000000, "total_business": 24472500, "rank_name": "Supreme", "rank_level": 10, "amount": 50000 },
    ]);

    const rankLevel = userRank?.rank;

    const nextRank = rankArray?.[rankLevel]?.rank_name;

    const MaxKingMaker = (rankArray?.[rankLevel]?.business) * 0.4;
    const MaxSecondLeg = (rankArray?.[rankLevel]?.business) * 0.3;
    const MaxOther = (rankArray?.[rankLevel]?.business) * 0.3;
    let PreviousMaxKingMaker = 0;
    let PreviousMaxSecondLeg = 0;
    let PreviousMaxOther = 0;
    if (rankLevel > 0) {
        const previousRank = rankLevel - 1;

        PreviousMaxKingMaker = (rankArray?.[previousRank]?.total_business) * 0.4;
        PreviousMaxSecondLeg = (rankArray?.[previousRank]?.total_business) * 0.3;
        PreviousMaxOther = (rankArray?.[previousRank]?.total_business) * 0.3;
    }

    // console.log('PreviousMaxKingMaker--', PreviousMaxKingMaker);
    // console.log('PreviousMaxSecondLeg--', PreviousMaxSecondLeg);
    // console.log('PreviousMaxOther--', PreviousMaxOther);
    // console.log('legBusiness.firstleg--', legBusiness.firstleg);
    // console.log('legBusiness.secondleg--', legBusiness.secondleg);
    // console.log('legBusiness.thirdleg--', legBusiness.thirdleg);



    const readOnlyfuntion = () => {
        setReadOnly(false);
        setInputfocus(true);
    };

    const walletFocus = () => {
        setReadOnlywallet(false);
        setinputfocuswallet(true);
    };


    const PasswordFocus = () => {
        setReadOnlypassword(false);
        setinputfocuspassword(true);
    };

    const ToggleButton = () => {
        const add_class: HTMLElement | any = document.getElementById('tabs_sec');
        add_class.classList.toggle("only-responsive-show");
    }

    const ToggleButtonRemove = () => {
        const remove_class: HTMLElement | any = document.getElementById('tabs_sec');
        remove_class.classList.remove("only-responsive-show");
    }

    useEffect(() => {
        getUserAllData();
        getIncomeData(selectedType);
        getDataWithdraw();
    }, [itemOffset, limit, selectedType]);


    const getUserAllData = async () => {

        setProgress(10);
        setIsTrue(true);

        let data = await _fetch(`${api_url}profile/getUser?username=${username}`, "GET", {})

        if (data?.status === "success") {
            setAllData(data?.data);
            setLegBusiness(data?.data?.legBusiness?.business)
            setUserData(data?.data?.userInfo);
            setFirstName(data?.data?.userInfo?.user_info?.firstName);
            setLastName(data?.data?.userInfo?.user_info?.lastName);
            setEmail(data?.data?.userInfo?.email);
            setPhone(data?.data?.userInfo?.phone);
            setBio(data?.data?.userInfo?.user_info?.bio);
            setCountry(data?.data?.userInfo?.user_info?.country);
            setBEP20(data?.data?.userInfo?.user_info?.wallet_addresses?.BEP20);
            setTRC20(data?.data?.userInfo?.user_info?.wallet_addresses?.TRC20);
            setSponsorData(data?.data?.sponserInfo);
            setIsCheckBlockStatus(data?.data?.userInfo?.disable);
            setIsCheckWithdrawStatus(data?.data?.userInfo?.withdraw_status);

            setProgress(100);
            setIsTrue(false);
        }
    }

    const getIncomeData = async (type: any) => {


        let incomeData = await _fetch(`${api_url}income/userIncomeHistory?username=${username}&type=${type}&sort=${sort}&page=${itemOffset}&limit=${limit}`, "GET", {})

        if (incomeData?.status === "success") {
            setIncomeData(incomeData?.data?.data);
            setTotal(incomeData?.total);
        }
        if (itemOffset > 1) {
            setKeycount(0);
            var count = itemOffset - 1;
            setKeycount(count * limit);
        }
    }

    const handleTabClick = (type: any) => {
        setSelectedType(type);
        getIncomeData(type);
        setItemOffset(1)
    };

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };

    const UserBlockStatus = async () => {
        let data = {
            "username": userData?.username,
            "disable": isCheckBlockStatus === false ? true : false
        }

        let res = await _fetch(`${api_url}profile/disableUser`, "POST", data)
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    }

    const CheckWithdraw = async () => {
        let data = {
            "username": userData?.username,
            "withdraw_status": isCheckWithdrawStatus === false ? true : false
        }

        let res = await _fetch(`${api_url}profile/withdrawalDisableUser`, "POST", data)
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    }


    const [searchedValue, setSearchedValue] = useState("");
    const [searchedType, setSearchedType] = useState("username");
    const [tableRecords, settableRecords] = useState([]);
    const [withdrawkeyCount, setwithdrawKeycount] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const [WithdrawitemOffset, setWithdrawItemOffset] = useState(1);
    async function getDataWithdraw() {




        var searchData = `&search=username&value=${username}`;


        const Data = await _fetch(`${api_url}payout/allPayouts?sort=-created_at&page=${WithdrawitemOffset}&limit=10${searchData}`, 'GET', {});
        if (Data?.status === "success") {

            settableRecords(Data?.data?.data);
            //   setAllDetail(Data);
            setTotalData(Data?.total);
            setProgress(100);
            setIsTrue(false);
        }
        if (itemOffset > 1) {
            setwithdrawKeycount(0);
            var count = itemOffset - 1;
            setwithdrawKeycount(count * limit);
        }
    }

    const handleWithdrawPageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setWithdrawItemOffset(newOffset);
      };

      const updateWalletAddresses = async(e: any) => {
        e.preventDefault();
        let data = {
            "bep20": BEP20,
            "trc20": TRC20,
        } 
        console.log('data', data);
       
        let res = await _fetch(`${api_url}/profile/updatewallets`, "POST", data, {});
        if(res?.status == 'success') {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
       }

    return (
        <>
            <LoadingBar
                color='var(--primary-color)'
                progress={progress}
                containerClassName="LoadingBar_contain"
                className="loaderbar-s"
                onLoaderFinished={() => setProgress(0)}
            />

            <div className={isTrue ? 'loader-bg' : ''}></div>

            <div className='view-main'>

                <div className='coustom_container'>
                    <div className='view-main-heading'>
                        <h5>User All Details</h5>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="#">View User</a></li>
                        </ul>
                        <button type='button' className='btn toggle-button' onClick={ToggleButton}>
                            <MenuRounded />
                        </button>
                    </div>

                    <div className='view-u-sec'>
                        <div className='row main-row'>
                            <div className='col-12'>
                                <div className='row'>

                                    <div className='col-md-8'>
                                        <div className='row'>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Total Income</p>
                                                        <p className='u-balanace'>{allData?.totalIncome >= "0" ? `$${Number(allData?.totalIncome)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Available Income</p>
                                                        <p className='u-balanace'>{allData?.avaliableIncome >= "0" ? `$${Number(allData?.avaliableIncome)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Total Deposit</p>
                                                        <p className='u-balanace'>{allData?.TotalDeposit >= "0" ? `$${Number(allData?.TotalDeposit)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Avaliable Fund</p>
                                                        <p className='u-balanace'>{allData?.AvaliableDeposit >= "0" ? `$${Number(allData?.AvaliableDeposit)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Staking Incentive</p>
                                                        <p className='u-balanace'>{allData?.stakingIncentive >= "0" ? `$${Number(allData?.stakingIncentive)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Direct Business Incentive</p>
                                                        <p className='u-balanace'>{allData?.directBusinessIncentive >= "0" ? `$${Number(allData?.directBusinessIncentive)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Level Business Incentive</p>
                                                        <p className='u-balanace'>{allData?.levelBusinessIncentive >= "0" ? `$${Number(allData?.levelBusinessIncentive)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Royality Incentive</p>
                                                        <p className='u-balanace'>{allData?.royalityIncentive >= "0" ? `$${Number(allData?.royalityIncentive)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Permanent Reward</p>
                                                        <p className='u-balanace'>{allData?.permanentReward >= "0" ? `$${Number(allData?.permanentReward)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-xl-4 col-md-6'>
                                                <div className='card user-income-card'>
                                                    <div className='u-income-left'>
                                                        <p>Difference Business Incentive</p>
                                                        <p className='u-balanace'>{allData?.differenceBusinessIncentive >= "0" ? `$${Number(allData?.differenceBusinessIncentive)?.toFixed(2)}` : "-"}</p>
                                                    </div>
                                                    <div className='u-icome-ryt'>
                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="card leg_card">
                                            <div className="card-body">

                                                <p>Business</p>


                                                <div className="inner_data_values">
                                                    <div><h6>Main Leg (King Maker)</h6></div>
                                                    <div className="item-sec">
                                                        <p>{legBusiness?.firstleg - PreviousMaxKingMaker}</p>
                                                        <p>Max {MaxKingMaker}</p>
                                                    </div>

                                                </div>
                                                <div className="inner_data_values">
                                                    <div><h6>Leg 2</h6></div>
                                                    <div className="item-sec">
                                                        <p>{legBusiness?.secondleg - PreviousMaxSecondLeg}</p>
                                                        <p>Max {MaxSecondLeg}</p>
                                                    </div>

                                                </div>
                                                <div className="inner_data_values">
                                                    <div><h6>Other</h6></div>
                                                    <div className="item-sec">
                                                        <p>{legBusiness?.thirdleg - PreviousMaxOther}</p>
                                                        <p>Max {MaxOther}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='col-12'>
                                <Tabs>

                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='card user-info'>
                                                <div className='card-header'>
                                                    <div className='d-flex align-items-start justify-content-between w-100'>

                                                        <div className='w-75'>
                                                            <div className='user-avtar'>
                                                                <img src={avtar} alt="user-img" width="100%" />
                                                            </div>
                                                            <p className='user_iD mb-0'>ID:{userData?.username}</p>
                                                        </div>


                                                        <div className='u-info-settings'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle key="start" id="dropdown-start">
                                                                    <MoreVertRounded />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className='dropdown-menu-start'>
                                                                    {isCheckBlockStatus === false ?
                                                                        < li > <button className="dropdown-item" type='button' onClick={UserBlockStatus}>Block</button></li>
                                                                        :
                                                                        < li > <button className="dropdown-item" type='button' onClick={UserBlockStatus}>Unblock</button></li>
                                                                    }
                                                                    {isCheckWithdrawStatus === false ?
                                                                        <li><button className="dropdown-item" type='button' onClick={CheckWithdraw}>Withdraw ON</button></li>
                                                                        :
                                                                        <li><button className="dropdown-item" type='button' onClick={CheckWithdraw}>Withdraw OFF</button></li>
                                                                    }

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </div>

                                                    <div className='u-info-name'>
                                                        <div className=''>
                                                            <p>{allData?.userInfo?.user_info?.name ? allData?.userInfo?.user_info?.name : "-"}</p>
                                                            <h6>{allData?.userInfo?.user_info?.bio}</h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='card-body-sec'>
                                                    <div className='user-team-j1'>
                                                        <div className='j1-team'>
                                                            <p className='mb-2'>Sponsor : {allData?.sponserInfo?.user_info?.name}</p>
                                                            <h6 className='mt-2'>ID:<span>{userData?.sponsor_id}</span> </h6>

                                                        </div>

                                                        <div className='j1-team mt-3'>
                                                            <p>Total Directs : {userData?.directs?.total}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='' id='tabs_sec'>
                                                    <div className='overly-footer' onClick={ToggleButtonRemove}></div>
                                                    <div className='card-footer only-responsive' id='tabs_sec'>
                                                        <h5>How it work</h5>
                                                        <div className='main-tabs'>
                                                            <TabList>
                                                                <Tab>User Profile</Tab>
                                                                <Tab>Wallets</Tab>
                                                                <Tab>Team</Tab>
                                                                <Tab>Sponsor Infomation</Tab>
                                                                <Tab>Change Password</Tab>
                                                            </TabList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-8'>
                                            <TabPanel>
                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> User Information </h5>
                                                            <button type='button' className='btn edit-button' onClick={readOnlyfuntion}> Edit</button>
                                                        </div>

                                                        <div className={inputfocus ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className='d-sm-flex justify-content-between align-items-center mb-3 gap-3'>
                                                                    <div className="w-100">
                                                                        <label htmlFor="fname" className="form-label">First Name</label>
                                                                        <input type="text" className="form-control" id="fname" placeholder="Enter First Name" name="fname" readOnly={readOnly} value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Last Name</label>
                                                                        <input type="text" className="form-control" id="lname" placeholder="Enter Last Name" name="lname" readOnly={readOnly} value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="email" className="form-label">Email</label>
                                                                    <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" readOnly={readOnly} value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="phonenumber" className="form-label">Phone Number</label>
                                                                    <input type="number" className="form-control" id="phonenumber" placeholder="Enter Phone Number" name="phonenumber" readOnly={readOnly} value={phone} onChange={(e: any) => setPhone(e.target.value)} />
                                                                </div>


                                                                <div className="mb-3">
                                                                    <label htmlFor="pwd" className="form-label">Country</label>
                                                                    {/* <input type="password" className="form-control" id="pwd" name="pswd" readOnly={readOnly} value={country} onChange={(e: any) => setCountry(e.target.value)} /> */}
                                                                    <CountryDropdown classes="form-select" value={country} onChange={(val: string) => setCountry(val)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="comment">Address</label>
                                                                    <textarea className="form-control" placeholder='Address' rows={5} id="comment" name="text" readOnly={readOnly} value={bio} onChange={(e: any) => setBio(e.target.value)} ></textarea>
                                                                </div>

                                                                {inputfocus &&

                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabPanel>


                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Wallets </h5>
                                                            <button type='button' className='btn edit-button' onClick={walletFocus}> Edit</button>
                                                        </div>

                                                        <div className={inputfocuswallet ? 'card-body f-js-show' : 'card-body'} >
                                                            <form onSubmit={updateWalletAddresses}> 
                                                                <div className="mb-3">
                                                                    <label htmlFor="address_1" className="form-label">BEP20 Wallet Address</label>
                                                                    <input type="text" className="form-control" id="address_1" placeholder="change BEP20 Wallet address" name="fname" readOnly={readOnlywallet} value={BEP20} onChange={(e: any) => setBEP20(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="address_2" className="form-label">TRC20 Wallet Address</label>
                                                                    <input type="text" className="form-control" id="address_2" placeholder="change TRC20 Wallet address" name="lname" readOnly={readOnlywallet} value={TRC20} onChange={(e: any) => setTRC20(e.target.value)} />
                                                                </div>


                                                                {inputfocuswallet &&
                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update Wallet</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center mb-4'>
                                                            <h5 className='card-ui-heading mb-0'> Team</h5>
                                                        </div>

                                                        <div className='row'>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Total Team</p>
                                                                        <p className='u-balanace'>{allData?.totalTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Active Team</p>
                                                                        <p className='u-balanace'>{allData?.totalActiveTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Inactive Team</p>
                                                                        <p className='u-balanace'>{allData?.totalInactiveTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>


                                            <TabPanel>
                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Sponsor Information </h5>
                                                            {/* <button type='button' className='btn edit-button' onClick={readOnlyfuntion}> Edit</button> */}
                                                        </div>

                                                        <div className={inputfocus ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className='d-sm-flex justify-content-between align-items-center mb-3 gap-3'>
                                                                    <div className="w-100">
                                                                        <label htmlFor="fname" className="form-label">Sponsor Username</label>
                                                                        <input type="text" className="form-control" id="fname" name="fname" readOnly={readOnly} value={sponsorData?.username} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Sponsor Name</label>
                                                                        <input type="text" className="form-control" id="lname" name="lname" readOnly={readOnly} value={allData?.sponserInfo?.user_info?.name} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Sponsor Email</label>
                                                                        <input type="email" className="form-control" id="lname" name="lname" readOnly={readOnly} value={allData?.sponserInfo?.user_info?.email} />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="email" className="form-label">Sponsor Package</label>
                                                                    <input type="Number" className="form-control" id="email" name="email" readOnly={readOnly} value={`$${allData?.sponserInfo?.user_info?.package?.current_package}`} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="phonenumber" className="form-label">Sponsor Register At</label>
                                                                    <input type="text" className="form-control" id="phonenumber" name="phonenumber" readOnly={readOnly} value={dateformat(sponsorData?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")} />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabPanel>


                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Chnage Password </h5>
                                                            <button type='button' className='btn edit-button' onClick={PasswordFocus}> Edit</button>
                                                        </div>

                                                        <div className={inputfocuspassword ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address_1" className="form-label"> Password </label>
                                                                    <input type="text" className="form-control" id="address_1" placeholder="password" name="fname" readOnly={readOnlypassword} value={password} onChange={(e: any) => setPassword(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="address_2" className="form-label">Confirm Password</label>
                                                                    <input type="text" className="form-control" id="address_2" placeholder="confirm password" name="lname" readOnly={readOnlypassword} value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.target.value)} />
                                                                </div>


                                                                {inputfocuspassword &&
                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update Wallet</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </div>


                            {/* history --- section */}
                            <div className='col-12'>
                                <Tabs>

                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='card user-info'>

                                                <div className=''>
                                                    <div className='card-footer border-0 mt-0'>
                                                        <h5>All History</h5>
                                                        <div className='main-tabs'>
                                                            <TabList>
                                                                <Tab onClick={() => handleTabClick('staking_incentive')}>Staking Incentive</Tab>
                                                                <Tab onClick={() => handleTabClick('direct_business_incentive')}>Direct Business Incentive</Tab>
                                                                <Tab onClick={() => handleTabClick('level_business_incentive')}>Level Business Incentive</Tab>
                                                                <Tab onClick={() => handleTabClick('royality_incentive')}>Royality Incentive</Tab>
                                                                <Tab onClick={() => handleTabClick('permanent_reward')}>Permanent Reward</Tab>
                                                                <Tab onClick={() => handleTabClick('difference_business_incentive')}>Difference Business Incentive</Tab>
                                                                {/* <Tab onClick={() => handleTabClick('direct_business_incentive')}>Direct Profit</Tab>
                                                                <Tab onClick={() => handleTabClick('level_business_incentive')}> Layered Investment Returns</Tab>
                                                                <Tab onClick={() => handleTabClick('roi_income')}> Profit on Investment</Tab>
                                                                <Tab onClick={() => handleTabClick('royality_incentive')}>  Royalty Profit</Tab>
                                                                <Tab onClick={() => handleTabClick('global_lifetime_pool_income')}> Eternal Global Pool Profit</Tab> */}

                                                                {/* <Tab>Deposit History</Tab> */}
                                                                <Tab>Withdraw History </Tab>
                                                                {/* <Tab>Fund History</Tab> */}
                                                            </TabList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-8'>

                                            {/* Staking Incentive tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Staking Incentive</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>

                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>
                                                                {!incomeData ? (
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                )
                                                                    : (
                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )
                                                                }

                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
                        <div className='no-data-js'>
                            <img src={no_data} alt='no-data' width="80%" />
                        </div>
                    </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Direct Business Incentive tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Direct Business Incentive</h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Level</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData?.map((item: any, index: any) => {

                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                    <li>${item?.level}</li>
                                                                                    <li>{item?.description}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}


                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
                        <div className='no-data-js'>
                            <img src={no_data} alt='no-data' width="80%" />
                        </div>
                    </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Level Business Incentive tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Level Business Incentive</h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Level</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData?.map((item: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                    <li>${item?.level}</li>
                                                                                    <li>{item?.description}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}


                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
<div className='no-data-js'>
<img src={no_data} alt='no-data' width="80%" />
</div>
</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Royality Incentive tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Royality Incentive</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Level</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData?.map((item: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                    <li>${item?.level}</li>
                                                                                    <li>{item?.description}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}

                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
<div className='no-data-js'>
<img src={no_data} alt='no-data' width="80%" />
</div>
</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Permanent Reward tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Permanent Reward</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Level</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData?.map((item: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                    <li>${item?.level}</li>
                                                                                    <li>{item?.description}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}


                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
<div className='no-data-js'>
<img src={no_data} alt='no-data' width="80%" />
</div>
</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Difference Business Incentive tab */}
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Difference Business Incentive </h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Level</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>


                                                                {incomeData?.map((item: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                    <li>${item?.level}</li>
                                                                                    <li>{item?.description}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}


                                                            </div>

                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}

                                                            {/* <div className='h-js-1'>
<div className='no-data-js'>
<img src={no_data} alt='no-data' width="80%" />
</div>
</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Deposit History tab */}
                                            {/* <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Deposit History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel> */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Withdraw History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2 hj-2NH-header'>
                                                                    <ul>
                                                                        <li >#</li>
                                                                        {/* <li >Username</li> */}
                                                                        <li >Amount</li>
                                                                        <li >Deduction</li>
                                                                        <li >Payable Amount</li>
                                                                        <li >Status</li>
                                                                        <li >Remark</li>
                                                                        {/* <li >Wallet Address</li> */}
                                                                        <li >Date</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='hj-2 hj-2NH'>


                                                                    {
                                                                        tableRecords?.map((val: any, key: any) => {
                                                                            return (
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</li>
                                                                                    {/* <li>{val?.username}</li> */}
                                                                                    <li>${val?.amount?.toFixed(2)}</li>
                                                                                    <li>${val?.deduction?.toFixed(2)}</li>
                                                                                    <li>${val?.payable_amount?.toFixed(2)}</li>
                                                                                    <li>{val?.status}</li>
                                                                                    <li>{val?.remark}</li>
                                                                                    {/* <li>{val?.wallet_address}</li> */}
                                                                                    <li>{dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                                                    </li>
                                                                                </ul>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>

                                                               {!tableRecords && <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>}

                                                                {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                    breakLabel="..."
                                                                    nextLabel=">"
                                                                    onPageChange={handleWithdrawPageClick}
                                                                    pageRangeDisplayed={1}
                                                                    pageCount={totalData / 10}
                                                                    previousLabel="<"
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Fund History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel> */}

                                        </div>
                                        <div className='col-lg-8'>
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Deposit History </h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>2</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>3</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>4</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>5</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>6</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>7</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <ul className="pagination justify-content-center mb-0">
                                                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                            </ul>

                                                            {/* <div className='h-js-1'>
                                                                        <div className='no-data-js'>
                                                                            <img src={no_data} alt='no-data' width="80%" />
                                                                        </div>
                                                                    </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Withdraw History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Fund History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </div>
                                    </div>
                                </Tabs>
                            </div>

                        </div>
                    </div >

                </div >






            </div >

        </>
    );
}

export default ViewUser;
