import React, { useState } from "react";
// import { useState } from "../Assets/images/React App_files/bundle";

import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ArrowForward, } from "@mui/icons-material";
import Loader from "../config/Loader";

const SendDeposit = () => {

  const [userName, setUserName] = useState();
  const [type, setType] = useState("Credit");
  const [amount, setAmount] = useState();
  const [btnDisable, setbtnDisable] = useState(false);

  const SubmitFund = async () => {
    setbtnDisable(true)
    let data = {
      "username": userName,
      "amount": amount,
      "credit_type": type
    }

    let res = await _fetch(`${api_url}fund/fundManage`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
      setbtnDisable(false)

    }
    else {
      toasted.error(res?.message);
      setbtnDisable(false)
    }
  }



  const handleTabSelect = (typeValue: any) => {

    setType(typeValue); // Update the selected type when a tab is clicked
  };



  return (
    <div>
      <div className="fund-gic">
        <div className="coustom_container">
          <div className="row tabs_rows">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="left_sec">
                <div className="heading_2">Fund Distribution Overview</div>
                <p>Optimizing Returns: Strategic Fund Distribution for Maximum Impact and Sustainable Growth in Your Investment Portfolio</p>
                <ul>
                  <li>
                    <div className="icon_box"><ArrowForward /></div>
                    Allocation Strategy for Growth
                  </li>

                  <li>
                    <div className="icon_box"><ArrowForward /></div>
                    Diversification and Risk Management in Fund Distribution
                  </li>

                </ul>

                <div className="balance_flex">
                  <div className="balance hr_row">
                    <h6>22</h6>
                    <h5>All Participants</h5>
                  </div>

                  <div className="balance hr_row">
                    <h6>15</h6>
                    <h5>Current Balance</h5>
                  </div>

                  <div className="balance">
                    <h6>30</h6>
                    <h5>Total Balance</h5>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="react_tabs_main">
                <Tabs>
                  <TabList>
                    <Tab
                      //  className="react-tabs__tab credit_btn "
                      className={`react-tabs__tab credit_btn ${type === 'Credit' ? 'credit_btn' : ''}`}
                      onClick={() => handleTabSelect('Credit')}
                    >Credit</Tab>
                    <Tab
                      // className="react-tabs__tab  debit_btn" 
                      className={`react-tabs__tab debit_btn ${type === 'Debit' ? 'debit_btn' : ''}`}
                      onClick={() => handleTabSelect('Debit')}>Debit</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="inner_tabpanel">
                      <div className="form-group">
                        <label htmlFor="userID">User ID</label>
                        <input className="input-fields" id="userID" type="text" placeholder="User ID" onChange={(e: any) => setUserName(e.target.value)} value={userName} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="amount">Amount</label>
                        <input className="input-fields" type="number" placeholder="Amount" onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                      </div>

                      <div className="form-group m-0">
                        <button type="submit" className="button_form credit_btn_bg" onClick={SubmitFund} disabled={btnDisable}>

                          {btnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {btnDisable === false && 'Send '}

                        </button>
                      </div>
                    </div>

                  </TabPanel>
                  <TabPanel>
                    <div className="inner_tabpanel">
                      <div className="form-group">
                        <label htmlFor="userID">User ID</label>
                        <input className="input-fields" type="text" placeholder="User ID" onChange={(e: any) => setUserName(e.target.value)} value={userName} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="amount">Amount</label>
                        <input className="input-fields" type="number" id="amount" placeholder="Amount" onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                      </div>

                      <div className="form-group m-0">
                        <button type="submit" className="button_form debit_btn_bg" onClick={SubmitFund} disabled={btnDisable}>

                          {btnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {btnDisable === false && 'Send '}
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SendDeposit;
