import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AttachMoneyRounded, HomeTwoTone, Newspaper } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import toasted from "../config/toast";
import LoadingBar from 'react-top-loading-bar';
import Loader from "../config/Loader";


const WithdrawSettings = () => {

  const [allData, setAllData] = useState<{ minimum_withdraw?: string, maximum_withdraw?: string, withdraw_charges?: string }>({});
  const [minimunWithdraw, setMinimunWithdraw] = useState('');
  const [maximunWithdraw, setMaximunWithdraw] = useState('');
  const [withdrawCharges, setWithdrawCharges] = useState('');
  const [statusValue, setShowMinumum] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [withdrawStatusChange, setWithdrawStatusChange] = useState(false);
  const [activationChange, setActivationChange] = useState(false);
  const [loginChange, setLoginChange] = useState(false);
  // const [tokenValue,settokenValue] = useState(0);
  const [btnDisable, setbtnDisable] = useState(false);


  useEffect(() => {
    getWithdrawData();
  }, []);

  const getWithdrawData = async () => {

    setIsTrue(true)
    setProgress(10);

    let res = await _fetch(`${api_url}settings/settings`, "GET", {})

    if (res?.status === "success") {
      setAllData(res?.data);
      setMinimunWithdraw(res?.data?.minimum_withdraw);
      setMaximunWithdraw(res?.data?.maximum_withdraw);
      setWithdrawCharges(res?.data?.withdraw_charges);
      // settokenValue(res?.data?.tokenValue);

      setWithdrawStatusChange(res?.data?.withdraw_status);
      setActivationChange(res?.data?.activation_status);
      setLoginChange(res?.data?.user_login);
      setProgress(100);
      setIsTrue(false);
    }
  }

  const SubmitWithdraw = async () => {
    setbtnDisable(true)
    let data = {
      "minimum_withdraw": minimunWithdraw,
      "maximum_withdraw": maximunWithdraw,
      "withdraw_charges": withdrawCharges,
      // "tokenValue":tokenValue,
    }
    let res = await _fetch(`${api_url}settings/withdrawSettings`, "POST", data)

    if (res?.status === "success") {
      toasted.success(res?.message);
      getWithdrawData();
      setbtnDisable(false)

    }

    else {
      toasted.error(res?.message);
      setbtnDisable(false)
    }
  }

  const withdrawSettingsChange = () => {

    setWithdrawStatusChange((prevStatus) => {
      const newStatus = !prevStatus;
      withdrawChangeStatus(newStatus);
      return newStatus;
    });
  };

  const withdrawChangeStatus = async (newStatus: boolean) => {

    let data = {
      "withdraw_status": newStatus
    }
    let res = await _fetch(`${api_url}settings/withdrawProceedSettings`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }

  const handleActivationChange = () => {

    setActivationChange((prevStatus) => {
      const newStatus = !prevStatus;
      ActivationChangeStatus(newStatus);
      return newStatus;
    });
  };

  const ActivationChangeStatus = async (newStatus: boolean) => {

    let data = {
      "activation_status": newStatus
    }
    let res = await _fetch(`${api_url}settings/activationAccess`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }

  const handleLoginChange = () => {

    setLoginChange((prevStatus) => {
      const newStatus = !prevStatus;
      LoginChangeStatus(newStatus);
      return newStatus;
    });
  };

  const LoginChangeStatus = async (newStatus: boolean) => {

    let data = {
      "user_login": newStatus
    }
    let res = await _fetch(`${api_url}settings/userLoginAccess`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }

  return (
    <>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="page_info">
        <div className="coustom_container">
          <div className="d-flex align-items-center justify-content-center">

            <h6> <Newspaper /> Withdraw Settings</h6>
            <div className="inner_links_header">


              <a href="#" className="d-flex"> <HomeTwoTone className="me-2" /> Home</a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-details-user">
        <div className="coustom_container">
          <div className="tabl-news-card">
            <div className="row m-0">
              <div className="col-md-4">

                <div className="card first-card">
                  <div className="card-header">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AttachMoneyRounded className="icon payout" />
                    </div>
                    <div className="card-ryt-text">
                      <p>Withdraw Settings</p>
                    </div>
                  </div>

                  <div className="">
                    <form>
                      <div className="form-group mb-3 mt-3">
                        <label htmlFor="min-withdraw">Minimum Withdraw</label>
                        <input type="text" className="form-control" id="min-withdraw" placeholder="Minimum withdraw" name="min-withdraw" onChange={(e: any) => setMinimunWithdraw(e.target.value)} value={minimunWithdraw} />
                      </div>

                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="max-withdraw">Maximun Withdraw</label>
                        <input type="text" className="form-control" id="max-withdraw" placeholder="Maxmimun withdraw" name="max-withdraw" onChange={(e: any) => setMaximunWithdraw(e.target.value)} value={maximunWithdraw} />
                      </div>

                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="withdraw-charge"> Withdraw Charges</label>
                        <input type="text" className="form-control" id="withdraw-charge" placeholder="withdraw Charges" name="withdraw-charge" onChange={(e: any) => setWithdrawCharges(e.target.value)} value={withdrawCharges} />
                      </div>

                      <div className="">
                        <button type="button" className="btn btn-news" onClick={SubmitWithdraw} disabled={btnDisable}>

                        {btnDisable === true &&
                          <Loader LoaderTxt={'Please Wait...'} />
                        }
                        {btnDisable === false && 'Submit '}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="outer-table table-responsive">
                  <div className="t-head">
                    <div className="t-head-left">
                      <h5>Withdraw Settings</h5>

                      <div className="toggle-sec">
                        <label className="switch">
                          <input type="checkbox" className="switch-input" onChange={(e: any) => withdrawSettingsChange()} checked={withdrawStatusChange} />
                          <span className="switch-label" data-on="ON" data-off="OFF"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>

                    <div className="t-head-left">
                      <h5>Activation Settings</h5>

                      <div className="toggle-sec">
                        <label className="switch">
                          <input type="checkbox" className="switch-input" onChange={(e: any) => handleActivationChange()} checked={activationChange} />
                          <span className="switch-label" data-on="ON" data-off="OFF"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>

                    </div>

                    <div className="t-head-left">
                      <h5>Login Settings</h5>

                      <div className="toggle-sec">
                        <label className="switch">
                          <input type="checkbox" className="switch-input" onChange={(e: any) => handleLoginChange()} checked={loginChange}  />
                          <span className="switch-label" data-on="ON" data-off="OFF"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>

                  </div>

                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Minimun Withdraw</th>
                      <th>Maximun Withdraw	</th>
                      <th> Withdraw	Charges</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td className="date-sec text-nowrap"> ${allData?.minimum_withdraw ? allData?.minimum_withdraw : "0"}</td>
                      <td className="date-sec text-nowrap"> ${allData?.maximum_withdraw ? allData?.maximum_withdraw : "0"}</td>
                      <td className="date-sec text-nowrap"> {allData?.withdraw_charges ? allData?.withdraw_charges + "%" : "0%"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawSettings;
