import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/images/logo.png";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import _fetch from "../config/api";
import Loader from "../config/Loader";

const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);


  const loginSubmit = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();

    const loginData = {
      email: email,
      password: password,
    }
    var postLoginData = await _fetch(`${api_url}auth/login`, 'POST', loginData, {});

    if (postLoginData?.status === 'success') {

      localStorage.setItem("auth", 'true');
      localStorage.setItem("accessToken", postLoginData?.token);
      if (postLoginData?.status === 'success') {
        localStorage.setItem("user", JSON.stringify(postLoginData?.data?.user));
      }
      navigate("/dashboard");
      toasted.success('Login Successfully!');
      setbtnDisable(false)
    }
    else {
      localStorage.clear();
      setbtnDisable(false)
      toasted.error(postLoginData?.message);
    }
  }

  return (
    <>
      <div className="main-form-page">
        <div className="container">
          <div className="row form-set">
            <div className="card-body">
              <div className="form-design">
                <div className="sub-title">
                  <form onSubmit={loginSubmit}>
                    <div className="login-heading mb-4">
                      <Link to="/dashboard">
                        <img src={logo} alt="" />
                      </Link>
                      {/* <h4>Login</h4> */}
                      {/* <p>Ensure that your login page is served over HTTPS</p> */}
                    </div>
                    <div className="panel-form">
                      <div className="user-field">
                        <input type="email" placeholder="Email" onChange={(e: any) => setEmail(e.target.value)} value={email} />
                        <i className="fa-regular fa-envelope email-icon"></i>
                      </div>
                      <div className="password-field">
                        <input
                          type="password"
                          placeholder="Enter Password"
                          id="password"
                          onChange={(e: any) => setPassword(e.target.value)} value={password}
                        />
                        <i className="fa-solid fa-lock" id="lockicon"></i>
                      </div>
                    </div>
                    <div className="login-btn">
                      <button type="submit" className="button_login" disabled={btnDisable}>

                        {btnDisable === true &&
                          <Loader LoaderTxt={'Please Wait...'} />
                        }
                        {btnDisable === false && 'Login '}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
